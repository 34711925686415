@tailwind components;

@layer components {
  .byc-auth-acc-wallets {
    @apply w-full mb-6 flex flex-col justify-start items-center;
  }
  .byc-auth-acc-nfts {
    @apply w-full;
  }

  .byc-auth-acc-wallets > ul > li {
    @apply bg-slate-300 p-2 rounded-md w-full flex flex-row justify-between items-center;
  }

  .byc-auth-acc-wallets > ul > li[data-active='true'] {
    @apply bg-cyan-500 text-white;
  }
}