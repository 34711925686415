@tailwind components;

@layer components {
  .nft-preview {
    @apply rounded-md bg-white flex flex-col justify-start items-center w-16 shadow-sm shadow-slate-300 mb-2 mr-2;
  }

  .nft-preview > img {
    @apply w-full aspect-square rounded-t-md;
  }

  .nft-preview > text.nft-name {
    @apply text-center text-slightDarkBlue font-semibold text-xs;
  }
}