@tailwind components;

@layer components {
  .mint-details-pill, .mint-button {
    @apply px-6 h-12 rounded-[1.25rem] outline outline-1 outline-darkBlue text-darkBlue font-bold w-full max-w-[13rem] flex justify-center items-center;
  }

  .mint-details-pill[data-coming-soon="true"] {
    @apply outline-gray text-gray;
  }

  .mint-details-pill > text.details-price {
    @apply text-slightDarkBlue font-normal mr-2;
  }

  .mint-details-pill[data-coming-soon="true"] > text.details-price {
    @apply text-gray;
  }


  .mint-details-pill > text.details-minted {
    font-size: .75rem;
    line-height: .8rem;
  }

  .mint-button {
    @apply text-white relative bg-darkBlue outline-none;
  }

  .mint-modal-content {
    @apply h-full w-full flex flex-col justify-center items-center text-darkBlue;
  }

  /* Chrome, Safari, Edge, Opera */
  input.input-otp-number::-webkit-outer-spin-button,
  input.input-otp-number::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number].input-otp-number {
    -moz-appearance: textfield;
  }
}