/* @import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */

@import './main.css';
@import './nft.css';
@import './web3auth.css';
@import './mint-section.css';
@import './byc-auth-account.css';
@tailwind base;
@tailwind components;


@font-face {
    font-family: GilroyBold;
    src: url("./assets/fonts/Gilroy-Bold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: GilroyMedium;
    src: url("./assets/fonts/Gilroy-Medium.ttf") format("truetype");
  }
  
  @font-face {
    font-family: GilroySemiBold;
    src: url("./assets/fonts/Gilroy-SemiBold.ttf") format("truetype");
  }
  
  @font-face {
    font-family: GilroyRegular;
    src: url("./assets/fonts/Gilroy-Regular.ttf") format("truetype");
  }
  
  @font-face {
    font-family: GothamBold;
    src: url("./assets/fonts/GothamRndSSm-Bold.ttf") format("truetype");
  }

  @font-face {
    font-family: GothamBook;
    src: url("./assets/fonts/GothamRndSSm-Book.ttf") format("truetype");
  }

  @font-face {
    font-family: GothamMedium;
    src: url("./assets/fonts/GothamRndSSm-Medium.ttf") format("truetype");
  }


@tailwind utilities;
